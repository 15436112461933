<template>
  <b-card class="shop-categories">
    <div class="row mb-1">
      <div class="col-3 mobile-col-6">
        <label>{{ $t('Status') }}</label>
        <b-form-select
          v-model="filterDates.status"
          :options="$superApp.appConstants.statusList()"
        />
      </div>
      <div class="col-3 text-left d-flex flex-wrap align-items-center">
        <button
          class="btn btn-primary mt-2"
          @click="filterData"
        >
          {{ $t('Submit') }}
        </button>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="categoryRef"
          :button-label="$t('Add Category')"
          :columns="categoryColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint()"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showItemsModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Category' : 'Add Category'"
      size="lg"
      ok-only
      hide-footer
      :ok-title="$t('Save Changes')"
      @hidden="showItemsModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Category')">
            <b-form-input
              v-model="dataInfo.store_category_name"
              type="text"
              :class="{ 'is-invalid': !dataInfo.store_category_name && isDataSubmitted }"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Description')">
            <b-form-input
              v-model="dataInfo.store_category_description"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="dataInfo.store_category_status"
              :class="{ 'is-invalid': (dataInfo.store_category_status == '' || dataInfo.store_category_status == null) && isDataSubmitted }"
              :options="[
                {
                  text: 'Active',
                  value: '1',
                },
                {
                  text: 'Inactive',
                  value: '0',
                },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="dataInfo.picture"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <modal-footer
        :show-cancel="false"
        @ok="saveData"
      />
    </b-modal>
  </b-card>
</template>

<script>
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ImageInput from '../users/shared/ImageInput.vue'
import { useStoreUi } from './useStore'

export default {
  components: {
    GoodDataTable,
    ModalFooter,
    ImageInput,
  },
  data() {
    return {
      isDataSubmitted: false,
      filterDates: {
        status: ''
      },
      showItemsModal: false,
      dataInfo: {
        store_category_name: '',
        store_category_description: '',
        store_category_status: '',
        picture: '',
        store_id: 0,
        id: 0,
      },
      categoryColumns: [
        {
          label: 'Image',
          field: 'picture',
          preventExport: true,
          useHtml: true,
          align: 'center',
          excelHtml: props => props.picture,
          renderer: props => `<img src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" style="width: 40px; height: 40px; border-radius: 10%;" />`,
        },
        {
          label: 'Category',
          field: 'store_category_name',
        },
        // {
        //   label: 'Description',
        //   field: 'store_category_description',
        // },
        {
          label: 'Status',
          field: 'store_category_status',
          rField: 'store_category_status_text',
          align: 'center',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.store_category_status === 1 ? 'Active' : 'Inactive'),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editCategory(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.$swal({
                  title: this.$t('You want to change the status?'),
                  iconHtml: this.$helpers.swalIcon('confirm.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.changeCategoryStatus(props)
                  }
                })
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
  },
  methods: {
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.showItemsModal = true
      this.isDataSubmitted = false
    },
    filterData() {
      const payload = []
      if (this.filterDates.status) payload.push(`status=${this.filterDates.status}`)
      this.$refs.categoryRef.loadItems(payload.join('&'))
    },
    inputFile(payload) {
      if (this.dataInfo) this.dataInfo.picture = payload
    },
    updateItemsForShop() {
      const { updateCategory } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      formData.append('_method', 'PATCH')
      updateCategory({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveData() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showItemsModal = false
        this.isDataSubmitted = false
        if (this.dataInfo.id) {
          this.updateItemsForShop()
        } else {
          this.addItemsForShop()
        }
      })
    },
    getFormData() {
      const formData = new FormData()
      this.dataInfo.store_id = window.SuperApp.getters.userInfo().id
      Object.keys(this.dataInfo).forEach(x => {
        if (x === 'status') {
          formData.append(x, Number(this.dataInfo[x]))
        } else if (this.dataInfo[x]) {
          if (x === 'picture' && window.SuperApp.helper.withBaseUrl(this.dataInfo.picture)) {
            formData.append(x, this.dataInfo[x])
          } else if (x !== 'picture') {
            formData.append(x, this.dataInfo[x])
          }
        }
      })
      return formData
    },
    addItemsForShop() {
      const { createCategory } = useStoreUi()
      showLoader()
      const formData = this.getFormData()
      createCategory(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showItemsModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showItemsModal = true
          showErrorNotification(this, error)
        })
    },
    editCategory(props) {
      showLoader()
      const { getShopCategory } = useStoreUi()

      getShopCategory(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopItem = data.responseData
            Object.keys(this.dataInfo).forEach(x => {
              this.dataInfo[x] = this.shopItem[x]
            })
            this.dataInfo.picture = window.SuperApp.helper.image(this.dataInfo.picture)
            this.showItemsModal = true
          }
          hideLoader()
        })
        .catch(error => {
          this.showItemsModal = true
          showErrorNotification(this, error)
        })
    },
    changeCategoryStatus(props) {
      const payload = window.SuperApp.actions.cloneDeep(props)
      payload.store_category_status = payload.store_category_status === 0 ? 1 : 0
      this.dataInfo = payload
      this.updateItemsForShop()
    },
    getApiEndpoint() {
      return `/shop/categoryindex/${this.storeInfo.id}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
